import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import ps2_img from '../assets/images/planetside_div.avif';
import d2_img from '../assets/images/destiny2_div.avif';
import wf_img from '../assets/images/warfame_div.avif';
import sto_img from '../assets/images/star_trek_online_div.avif';
import ffxiv_img from '../assets/images/final_fantasy_xiv_div.avif';
import config from '../../config';

const IndexPage = () => (
  <Layout pageHeader={true} menuBar={false}>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li> 
            <a href={config.socialLinks.find(link => link.name === 'Discord').url} className="button primary">
              Join us
            </a>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="info">
        <a href="#info" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="info" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Our foundation built from its humble beginnings
          </h2>
          <p>
            Voodoo Shipping Company began with a group of US Navy friends starting a Minecraft server in 2010.
            <br />
            As the community grew, we learnt the Primordial Truth of humanity, devoting ourselves to the Realm of Chaos with the guidance of our blessed arch-sorcerers. Each of our game divisions have dedicated themselves as a house of worship for a chaotic force of the Immaterium.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fa-skull major style1">
              <span className="label">Skulls</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-tint major style2">
              <span className="label">Blood</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-crown major style3">
              <span className="label">Throne</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={ps2_img} alt="" />
        </div>
        <div className="content">
          <h2>
            PlanetSide 2
          </h2>
          <p>
            <i>Our Emerald NC outfit, Voodoo Company (VCO), is an infantry-focused outfit that specializes in relentless murder and mayham. We soak ourselves in the blood of the heretics for the honor and approval of Khorne. The death of our fallen champions do not matter as Nanites will provide a plentiful harvest to farm more skulls. MAIN! KILL! BURN!</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={d2_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Destiny 2
          </h2>
          <p>
            <i>Our D2 clan, Voodoo Shipping Company (VCO), is a PvX community that has acquired the accursed knowledge that the presumed Light's undying gift is actually bestowed to us by Grandfather Nurgle through the Darkness. With his pestilential favour, the Grand Garden of Nurgle awaits us behind the portals opened with the the motes of darkness. Greed is Good!</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={wf_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Warframe
          </h2>
          <p>
            <i>Our Warframe clan is a guild of Tenno's most yearning sycophants seeking to relish in the universe's greatest desires while wearing the freshest of fashion. Behind the lustful veil of the Lotus lies the Dark Prince to shower us with life's truest pleasures and glamour. Accepting the alluring Void yields a new seductive reality. Long shall be your suffering, joyous be your pain!</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={sto_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Star Trek Online
          </h2>
          <p>
            <i>Our STO Fleet, Voodoo Fleet, is an enlightened group that endeavors the forbidden fruits of untold knowledge to achieve Tzeentch's Grand Scheme. For logic is the beginning of wisdom, not the end, to live long and prosper. Our mission: to control strange new worlds; to manipulate new life and new civilizations; to boldly go where Architect of Fate has planned for us!</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={ffxiv_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Final Fantasy XIV
          </h2>
          <p>
            <i>Our FFXIV Free Company is an unadulterated legion that supports all Ruinous Powers, pursuiting the capture of the chaotic Warp that seeps into the world through the Lifestream. The other treasonous organized forces of Hydaelyn merely wants to hoard the Mothercrystal for themselves. NONE WILL STAND, NONE WILL FIGHT BACK, CHAOS TRIUMPHS, CHAOS IS UNDIVIDED! </i>
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Our culture and identity built from its foundation</h2>
          <p>
            We have flourished with the principle that we are a group of chaos cultists with singular ultimate goal: Daemon Prince Ascension of our Chaos Lords.
            <br />
            All of our activities revolve around serving our Chaos deities and destroying the falsehoods of the Imperium.
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-comment">
            <h3>Socialize</h3>
            <p>
              We stay engaged through our various social media platforms and in-game communities managed by a dedicated staff.
            </p>
          </li>
          <li className="icon solid fa-gamepad">
            <h3>Play</h3>
            <p>
              Aside from our official divisions, there are plenty of others games that we play together or sometimes try to build something more in.
            </p>
          </li>
          <li className="icon solid fa-music">
            <h3>Express</h3>
            <p>
              Whether it be an intriguing conversation, content worth sharing, or karaoke night, you should be able to be yourself that you want to be.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Respect</h3>
            <p>
              Everybody love everybody to promote a better well-being, collective, and sense of belonging.
            </p>
          </li>
          <li className="icon solid fa-hands-helping">
            <h3>Give</h3>
            <p>
              It is important to give back to others through our semi-annual charity fundraisers and external community building activities.
            </p>
          </li>
          <li className="icon solid fa-seedling">
            <h3>Grow</h3>
            <p>
              To foster a thriving community, we are always aspiring to find new ways to build more on our foundation.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Voodoo Shipping Company</h2>
          <p>
            Come join us on our Discord server to commit yourself to the Realm of Chaos.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href={config.socialLinks.find(link => link.name === 'Discord').url} className="button fit primary">
              Join us
            </a>
          </li>
          <li>
            <Scroll type="id" element="info">
              <a href="#info" className="button fit">
                Learn More
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
